
.custom-modal-antd-camera .ant-modal-content{
  border-radius: 30px 30px 0px 0px;
}

.custom-modal-antd-camera2 .ant-modal-content{
  border-radius: 0px 0px 0px 0px;
}

.custom-modal-antd-camera2 > .ant-modal-content > .ant-modal-body {
  padding-right: 0px;
  padding-left: 0px;
  padding-bottom: 10px;
  padding-top: 5px;
}

.custom-modal-antd-camera .ant-modal-header {
  border-radius: 30px 30px 0px 0px;
}

.custom-modal-antd-camera > .ant-modal-content > .ant-modal-body {
  padding-right: 0px;
  padding-left: 0px;
  padding-bottom: 10px;
  padding-top: 5px;
}

.custom-modal-antd-camera-2 > .ant-modal-content > .ant-modal-body {
  padding: 0px;
}

.custom-modal-antd-camera .ant-modal-title {
  text-align: center;
  font-weight: 600;
}

.spin-custom {
  height: 100%;
  color: aliceblue;
}

.spin-custom .ant-spin-container {
  height: 100%
}

.spin-custom .ant-spin-text{
  color: #009B4B;
  font-size: 16px;
}

.anticon-zoom-in{
  display: none;
}

.anticon-zoom-out{
  display: none;

}

.modal-custom .ant-modal-body{
  padding: 15px;
}

.modal-terms .ant-modal-body{
  padding: 24px;
}
.modal-terms .ant-modal-content{
  border-radius: 24px;
}